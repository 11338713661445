















import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class PageHeader extends Vue {
  @Prop() title!: string;
}
